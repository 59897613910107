$body-color                        : #1c202b;
$headings-color                    : $body-color;

$border-radius-lg                  : .625rem;

$navbar-toggler-padding-x          : 0;
$navbar-light-toggler-border-color : transparent;

$input-btn-focus-width             : .2rem;
//$input-border-color                : transparent;
//$input-focus-border-color          : transparent;
//$input-focus-box-shadow           : 0 0 .2rem $input-btn-focus-width darken($body-bg, 2%);

$spinner-border-width-sm           : 0.1em;
