html,
body {
    overflow-x : hidden; /* Prevent scroll on narrow devices */
}

#root {
    height : 100vh;
}

.overlay {
    position         : fixed;
    top              : 0;
    bottom           : 0;
    left             : 0;
    right            : 0;
    background-color : rgba(0, 0, 0, 0.3);
    animation        : fade-in .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

main {
    padding-bottom: 15px;
}

@keyframes fade-in {
    0% {
        opacity : 0;
    }
    100% {
        opacity : 1;
    }
}

@media (max-width : 600px) {
    .row {
        flex-direction: column;
    }
}
