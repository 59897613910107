.offcanvas-collapse {
    position   : fixed;
    top        : 0;
    bottom     : 0;
    left       : 0;
    right      : 0;
    overflow-y : auto;
    visibility : hidden;
    transform  : translateX(-100%);
    transition : visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transition : transform .3s ease-in-out, visibility .3s ease-in-out;
    transition : transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
    z-index    : 1000;

    &.open {
        visibility        : visible;
        -webkit-transform : translateX(0);
        transform         : translateX(0);
    }

    .navbar-wrapper {
        background : #ffffff;
        height     : 100vh;
        overflow   : hidden;
        padding    : 0;
        max-width  : 130px;

        .navbar-close-icon {
            color          : rgba(0, 0, 0, 0.5);
            padding        : 0.5rem 1.5rem;
            font-size      : 1.5rem;
            vertical-align : middle;
        }

        .submenu {
            position   : absolute;
            top        : 55px;
            left       : 130px;
            width      : calc(100vw - 130px);
            background : #212a30;
            height     : calc(100vh - 55px);
            visibility : hidden;

            &.open {
                visibility : visible;

                .nav-link.active {
                    text-decoration : underline;
                }
            }
        }

        .navbar-inner-wrapper {
            height     : 100%;
            overflow-y : auto;

            > .navbar-nav {
                align-items   : center;
                font-size     : 11px;
                font-weight   : bold;
                margin-bottom : 2.5rem;
                padding       : 1rem;

                > .nav-item {
                    margin : 1rem 0;

                    .nav-link {
                        align-items : center;
                        display     : flex;
                        text-align  : center;
                    }

                    .nav {
                        .nav-link {
                            color       : #ffffff;
                            font-size   : .85rem;
                            font-weight : normal;
                            padding     : .5rem 1rem;

                            > svg {
                                font-size    : 1rem;
                                margin-right : .75rem;
                            }
                        }
                    }

                    > .nav-link {
                        color           : #cccccc;
                        flex-direction  : column;
                        justify-content : center;

                        &.active {
                            color : #1c202b;

                            > svg {
                                color : inherit;
                            }
                        }
                    }
                }
            }

            svg {
                color     : #cccccc;
                font-size : 2.5rem;
            }
        }
    }
}

.notification {
    margin-left  : 8px;
    margin-right : 8px;
    position     : static;

    > .dropdown-menu {
        margin    : 0 !important;
        padding   : 0 !important;
        width     : 100vw;
        top       : 56px !important;
        transform : none !important;
    }

    &--unread {
        background : $light;
    }

    &__amount {
        border         : 2px solid $white;
        padding-bottom : .2em;
        padding-top    : .2em;
        position       : absolute;
        top            : 7px;
        right          : 15px;
    }
}
